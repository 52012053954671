import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Dcontent from "./components/dashboard/content/Dcontent";

const Login = lazy(() => import("./pages/login/Login"));

const Dashboard = lazy(() => import("./dashboard/home/Home"));

const Brands = lazy(() => import("./dashboard/brands/Brands"));
const Category = lazy(() => import("./dashboard/category/Category"));
const Users = lazy(() => import("./dashboard/users/Users"));
const Profile = lazy(() => import("./dashboard/users/Profile"));
const Orders = lazy(() => import("./dashboard/orders/Orders"));
const Products = lazy(() => import("./dashboard/products/Products"));
const PointTransfer = lazy(() => import('./dashboard/pointTransfer/PointTransfer'));
const Withdraw = lazy(() => import('./dashboard/withdraw/Withdraw'));
const PromotionHistory = lazy(() => import('./dashboard/promotionHistory/PromotionHistory'));
const PromotionAchiver = lazy(() => import('./dashboard/promotionAchiver/PromotionAchiver'));
const GroupSales = lazy(() => import('./dashboard/groupSales/GroupSales'));

function App() {
  return (
    <Suspense fallback={"Loading..."}>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dcontent />}>
          <Route index element={<Dashboard />} />
          <Route path="brands" element={<Brands />} />
          <Route path="category" element={<Category />} />
          <Route path="users" element={<Users />} />
          <Route path="profile/:id" element={<Profile />} />
          <Route path="orders" element={<Orders />} />
          <Route path="products" element={<Products />} />
          <Route path="point-transfer" element={<PointTransfer />} />
          <Route path="withdraw" element={<Withdraw />} />
          <Route path="promotion-history" element={<PromotionHistory />} />
          <Route path="promotion-achiver" element={<PromotionAchiver />} />
          <Route path="group-sales" element={<GroupSales />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
