import cl from "./Dsidebar.module.css";
import cll from "../topbar/Dtopbar.module.css";
import { NavLink } from "react-router-dom";

import logo from "../../../assets/identity/icon-logo-l.png";
import dashboardNavData from "../../../store/dashboard_sidebar";
import Single from "./navItems/Single";
import DropDown from "./navItems/DropDown";

function Dsidebar() {
  function closeSideBar() {
    if (window.innerWidth <= 768) {
      document.querySelector("#dashboardSideBar").classList.toggle(cll.visible);
      document.querySelector("#dham").classList.toggle(cll.display);
      document.querySelector("#dcross").classList.toggle(cll.display);
    }
  }
  return (
    <div className={cl.container} id="dashboardSideBar">
      <div className={cl.logo}>
        <NavLink to={"/"}>
          <img src={logo} alt="Icon" /> 
        </NavLink>
      </div>
      <div className={cl.nav_items_container}>
        {dashboardNavData.map((c, i) => {
          return (
            <div
              key={`${c.navItemContainer}--${i}`}
              className={cl.nav_item_container}
            >
              <h5>{c.navItemContainer}</h5>
              {c.navItems.map((d) => {
                return d.dropdown ? (
                  <DropDown
                    key={d.url}
                    title={d.title}
                    dropDownContainer={d.dropDownItems}
                    closeSideBar={closeSideBar}
                  />
                ) : (
                  <Single
                    key={d.url}
                    url={d.url}
                    text={d.text}
                    closeSideBar={closeSideBar}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Dsidebar;
